(() => {
    function sum(...args) {
      const calculate = () => args.reduce((acc, val) => acc + val, 0);
  
      function add(...nextArgs) {
        return sum(...args, ...nextArgs);
      }
  
      Object.defineProperty(add, 'valueOf', { value: calculate });
  
      return add;
    }
  
    console.log(sum(1, 2)(3));
  })();